import React, { useRef } from 'react';
import '../App.css';  // 如果是 App.css

const Message = ({ message }) => {
    const isUserMessage = message.role === 'user';
    const messageRef = useRef(null);

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: isUserMessage ? 'row-reverse' : 'row',  // 交换方向
            alignItems: 'flex-start', 
            marginBottom: '10px',
            justifyContent: 'flex-start'
        }}>
            {/* 头像 */}
            <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                margin: isUserMessage ? '0 0 0 10px' : '0 10px 0 0', // 左右间距根据用户角色不同
                backgroundColor: isUserMessage ? '#4CAF50' : '#FFC107', // 交换背景色
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {isUserMessage ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: '30px', height: '30px' }}>
                        <circle cx="50" cy="50" r="30" fill="#4CAF50" />
                        <text x="50" y="55" fontSize="25" textAnchor="middle" fill="#fff"></text>
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: '30px', height: '30px' }}>
                        <circle cx="50" cy="50" r="30" fill="#FFC107" />
                        <text x="50" y="55" fontSize="25" textAnchor="middle" fill="#fff"></text>
                    </svg>
                )}
            </div>

            {/* 消息气泡 */}
            <div style={{
                maxWidth: '70%', 
                padding: '10px', 
                borderRadius: '10px', 
                backgroundColor: isUserMessage ? '#d1f7d1' : '#f1f1f1', // 交换背景色
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)', 
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                marginLeft: isUserMessage ? '0' : '10px',
                marginRight: isUserMessage ? '10px' : '0',  // 调整气泡边距
                textAlign: isUserMessage ? 'right' : 'left' // 设置文本对齐方式
            }} ref={messageRef}>
                <strong>{isUserMessage ? '你' : 'kAItty'}:</strong> {message.content}
            </div>
        </div>
    );
};

export default Message;
