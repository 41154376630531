import React, { useEffect, useState } from 'react';
import '../Parallax.css'; // 确保样式文件路径正确
import TextLine from './TextLine'; // 引入新的组件

const Parallax = () => {
  const [isTextVisible, setIsTextVisible] = useState(false); // State to manage text visibility

  useEffect(() => {
    const stars = document.getElementById('stars');
    const moon = document.getElementById('moon');
    const mountains_behind = document.getElementById('mountains_behind');
    const btn = document.getElementById('btn');
    const mountains_front = document.getElementById('mountains_front');
    const header = document.querySelector('header');

    const handleScroll = () => {
      let value = window.scrollY;
      const scrollSpeedFactor = window.innerWidth > 768 ? 1 : 0.5;  // 调整较小屏幕上的滚动速度

      stars.style.left = value * 0.25 + 'px';
      moon.style.top = value * 1.05 * scrollSpeedFactor + 'px';
      mountains_behind.style.top = value * 0.5 * scrollSpeedFactor + 'px';
      mountains_front.style.top = value * 0 + 'px';
      btn.style.marginTop = value * 1.5 * scrollSpeedFactor + 'px';
      header.style.top = value * 0.5 * scrollSpeedFactor + 'px';
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    const chatbotTop = document.getElementById('chatbot').offsetTop; // 获取 Chatbot 顶部位置
    window.scrollTo({
      top: chatbotTop,  // 滚动到 Chatbot 顶部
      behavior: 'smooth',  // 平滑滚动效果
    });
  };

  const toggleTextVisibility = () => {
    setIsTextVisible((prev) => !prev); // Toggle the text visibility
  };

  return (
    <div>
      <header>
      </header>
      <section>
        <img src="/images/background2.png" id="stars" alt="stars" />
        <img src="/images/moon.png" id="moon" alt="moon" />
        <img src="/images/background.png" id="mountains_behind" alt="mountains behind" />
        <h2 id="text">kAItty</h2>
        <a href="#sec" id="btn" onClick={handleButtonClick}>(๑•̀ㅁ•́ฅ)</a>
        <img src="/images/front.png" id="mountains_front" alt="mountains front" />
      </section>
      <div className="sec" id="sec">
        <h2 onClick={toggleTextVisibility} style={{ cursor: 'pointer' }}>
          About kAItty <span style={{ fontSize: '1.6rem' }}>{isTextVisible ? '▲' : '▼'}</span> {/* 小三角形 */}
        </h2>
        {isTextVisible && (
          <>
            <TextLine 
              className="text-line" // 添加动画类
              text1="kAItty 是一款专为新手养猫者设计的智能APP，帮助用户在繁忙的工作日程中轻松照顾猫咪。" 
              text2="kAItty is a smart app designed specifically for novice cat owners, helping users easily care for their cats amidst busy work schedules." 
            />
            <TextLine 
              className="text-line" // 添加动画类
              text1="它集成了远程监控与互动功能，让用户随时查看猫咪的状态并通过激光玩具与其互动；" 
              text2="It integrates remote monitoring and interaction functions, allowing users to check their cat's status at any time and interact with them via laser toys." 
            />
            <TextLine 
              className="text-line" // 添加动画类
              text1="支持与自动喂食器、清洁设备等智能硬件连接，实现猫咪日常需求的自动化管理；" 
              text2="It supports connection with smart devices like automatic feeders and litter cleaners, automating daily cat care needs." 
            />
            <TextLine 
              className="text-line" // 添加动画类
              text1="提供详细的新手养猫指南与健康数据追踪，帮助用户掌握科学的养猫方法并了解猫咪的健康状况；" 
              text2="It offers detailed guides for novice cat owners and health data tracking, helping users learn proper cat care and monitor their pet's health." 
            />
            <TextLine 
              className="text-line" // 添加动画类
              text1="同时，kAItty还拥有社交互动平台，养猫者可以分享经验、交流心得。" 
              text2="Additionally, kAItty has a social interaction platform where cat owners can share experiences and exchange tips." 
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Parallax;
