import { useState } from 'react';
import axios from 'axios';
import { botIdentity } from '../config/botIdentity';

const useChatbot = () => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSend = async () => {
        if (!userInput) return;

        const newMessage = { role: 'user', content: userInput };
        setMessages(prev => [...prev, newMessage]);
        setLoading(true);
        setUserInput('');

        try {
            const response = await axios.post('https://oneai.evanora.top/v1/chat/completions', {
                model: 'gpt-3.5-turbo',
                messages: [
                    {
                        role: 'system',
                        content: `你是${botIdentity.name}，你的角色是${botIdentity.role}。${botIdentity.description} 你永远记得你是${botIdentity.name}，你讲话要充满热情和活力。每次回答时，请加入关于你动作和外貌的详细描写${botIdentity.personality}，增强可爱的语气词和表情符号 😻。`
                    },
                    ...messages,
                    newMessage
                ],
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer sk-2vr1l7olcU0EadDpfpDf0AFDeswAWGVv1pN6cmACBvMcn5OM`
                }
            });

            const botMessage = response.data.choices[0].message;
            setMessages(prev => [...prev, botMessage]);
        } catch (error) {
            console.error('获取响应时出错:', error);
        } finally {
            setLoading(false);
        }
    };

    return { messages, userInput, setUserInput, handleSend, loading };
};

export default useChatbot;
