// Loader.js
import React from 'react';
import '../Loader.css'; // 引入加载器样式

const Loader = () => {
  return (
    <div className="loader-container">  {/* 添加 loader-container 包裹 loader */}
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
