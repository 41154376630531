import React from 'react';

const TextLine = ({ text1, text2 }) => {
  return (
    <div className="text-line">
      <p>{text1}</p>
      <p>{text2}</p>
    </div>
  );
};

export default TextLine;
