import React, { useState } from 'react';
import '../Header.css';  // 引入CSS样式

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);  // 控制菜单的显示与隐藏

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);  // 切换菜单状态
  };

  const scrollToNextSection = (e, scrollTarget) => {
    e.preventDefault();  // 阻止默认行为

    // 如果是 Contact 链接，滚动到页面底部
    if (scrollTarget === 'bottom') {
      window.scrollTo({
        top: document.body.scrollHeight,  // 滚动到页面底部
        behavior: 'smooth',  // 平滑滚动效果
      });
    } else if (scrollTarget === 'chatbot') {
      // 如果是 Services 链接，滚动到 Chatbot 顶部
      const chatbotTop = document.getElementById('chatbot').offsetTop; // 获取 Chatbot 顶部位置
      window.scrollTo({
        top: chatbotTop,  // 滚动到 Chatbot 顶部
        behavior: 'smooth',  // 平滑滚动效果
      });
    } else {
      window.scrollTo({
        top: window.innerHeight * scrollTarget,  // 滚动到指定页数（视口高度的倍数）
        behavior: 'smooth',  // 平滑滚动效果
      });
    }
  };

  return (
    <header className="header">
      <div className="header-container">
        <h1 className="logo">kAItty</h1> {/* Logo部分 */}

        {/* 汉堡按钮：仅在手机上显示 */}
        <div className="menu-toggle" onClick={toggleMenu}>
          &#9776; {/* 三条杠 */}
        </div>

        <nav>
          <ul className={`nav-links ${menuOpen ? 'show' : ''}`}>
            <li><a href="#home">Home</a></li> {/* 修改Home按钮 */}
            <li><a href="#about" onClick={(e) => scrollToNextSection(e, 1)}>About</a></li> {/* 滚动一页 */}
            <li><a href="#services" onClick={(e) => scrollToNextSection(e, 'chatbot')}>Services</a></li> {/* 滚动到Chatbot顶部 */}
            <li><a href="#contact" onClick={(e) => scrollToNextSection(e, 'bottom')}>Contact</a></li> {/* 滚动到底部 */}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
