export const allQuestions = [
    "😺 如何照顾我的猫？",
    "😷 我的猫生病了，我该怎么办？",
    "🍽️ 猫的饮食建议是什么？",
    "🎓 如何训练我的猫？",
    "🤔 猫的常见行为问题有哪些？",
    "🐾 猫的社交行为如何？",
    "🧸 如何选择适合我的猫的玩具？",
    "🩺 猫咪的健康检查应该包括哪些内容？",
    "🌿 如何减少猫咪的压力？",
    "🔍 猫的行为有哪些误解？",
    "🏠 如何为我的猫创造一个安全舒适的环境？",
    "🎉 我应该如何庆祝我猫的生日？",
    "🚗 如何准备猫咪的旅行？",
    "🛁 如何给猫洗澡？",
    "🐱 猫咪的正常体重应该是多少？",
    "💤 猫的睡眠习惯是怎样的？",
    "🧑‍⚕️ 猫的疫苗接种有哪些重要性？",
    "🎭 猫的情绪如何表现？",
    "💊 我该如何给猫服药？",
    "🧑‍🎓 如何了解我的猫的性格特征？",
    "🌍 猫咪对环境的适应能力如何？",
    "🧩 如何刺激猫咪的智力？",
    "🎶 猫咪对音乐有反应吗？",
    "👶 猫能和小孩和平相处吗？",
    "🌼 如何选择猫咪的室内植物？",
    "🏃‍♂️ 猫需要多少运动量？",
    "📅 猫的最佳绝育时间是什么时候？",
    "🚫 如何识别和预防猫的寄生虫？",
    "💧 猫咪的水摄入量应该是多少？",
    "🧹 如何保持猫咪的卫生和清洁？",
    "🌈 猫咪的年龄如何影响它们的行为？",
    "📦 猫为什么喜欢盒子？",
    "👩‍⚕️ 何时需要带猫去看兽医？",
    "🎈 如何避免猫咪的孤独感？",
    "🍬 猫能吃哪些人类食物？",
    "💔 如果我需要离开猫咪，该怎么办？",
    "🔄 如何帮助猫咪适应新环境？",
    "💡 如何教猫咪使用猫砂盆？",
    "🎣 如何与猫咪进行互动游戏？",
    "🧼 猫的洗澡频率应该是多少？",
    "🪴 猫咪适合哪些种类的草？",
    "📸 如何为猫咪拍出好看的照片？",
    "🎤 猫的叫声有什么含义？",
    "🌃 如何处理猫咪的夜间活动？",
    "🔒 如何确保家中猫咪的安全？",
    "🧳 如何打包猫咪的旅行用品？",
    "🦠 猫咪的免疫系统如何运作？",
    "🖌️ 如何选择猫咪的洗澡产品？",
    "🚿 猫咪需要多频繁洗澡？",
    "👀 如何识别猫咪的健康问题？",
    "🌞 猫咪喜欢阳光吗？",
    "🌈 如何帮助猫咪缓解焦虑？",
    "📑 猫咪的日常护理包括哪些内容？",
    "🐾 猫咪的爪子如何护理？",
    "🤲 如何与猫咪建立信任关系？",
    "🔄 猫咪会记住主人吗？",
    "📚 猫咪的品种差异有哪些？",
    "🐾 如何处理猫咪之间的争斗？",
    "🏆 如何为猫咪选择最佳食品？",
    "🕰️ 猫咪的生活习惯应该如何调整？",
    "🦮 如何帮助视力受限的猫咪？",
    "🎨 如何为猫咪设计一个舒适的休息区？",
    "🔑 猫咪的社交技巧有哪些？",
    "⚖️ 猫咪的体重管理应该注意什么？",
    "🚧 如何避免猫咪进食危险物品？",
    "🎉 如何让猫咪感到特别？",
    "🐶 猫咪能和狗和平相处吗？",
    "🌏 如何选择适合的猫咪种类？",
    "🤔 猫咪为什么会追逐光点？",
    "🔍 如何观察猫咪的行为变化？",
    "💚 如何帮助猫咪减轻孤独感？",
    "🎁 给猫咪选择什么样的礼物？",
    "🍏 猫咪是否可以吃水果？",
    "🌪️ 猫咪在风暴期间如何处理？",
    "🎢 猫咪为什么喜欢高处？",
    "🎇 如何确保猫咪在节日时安全？",
    "👂 如何处理猫咪的耳朵问题？",
    "🐈 猫咪能吃哪些宠物食物？",
    "🏋️‍♂️ 如何帮助猫咪保持活力？",
    "🧑‍⚕️ 如何选择合适的兽医？",
    "🐾 如何处理猫咪的焦虑行为？",
    "🌱 猫咪的草药护理方法有哪些？",
    "📞 如何联系宠物医院？",
    "🗺️ 如何让猫咪适应新的家庭成员？",
    "🛌 如何选择猫咪的睡垫？",
    "🧴 猫咪的美容有哪些建议？",
    "🔑 猫咪的微芯片如何工作？",
    "🦎 猫咪对其他小动物的反应如何？",
    "🐾 如何帮助流浪猫？",
    "💼 如何带猫咪上班？",
    "🌹 如何识别猫咪的喜好？",
    "🏡 如何设计猫咪的游乐区？",
    "🧠 猫咪的智商如何评估？",
    "🚪 如何处理猫咪的门口行为？",
    "🧘 如何帮助猫咪放松？",
    "💭 猫咪的梦境会是什么？",
    "🔄 如何帮助猫咪调整作息？",
    "🎶 如何利用音乐帮助猫咪放松？",
    "🚪 猫咪是否需要外出活动？",
    "📦 如何处理猫咪的好奇心？",
    "📖 猫咪的历史背景是什么？",
    "🎨 如何为猫咪制作手工玩具？",
    "🌅 如何创建猫咪的日常Routine？",
    "🌟 如何评估猫咪的情感健康？",
    "🌈 猫咪的多样性如何影响行为？",
    "🧬 如何理解猫咪的基因遗传？",
    "🐾 如何选择合适的猫砂品牌？",
    "🖼️ 如何为猫咪制作相册？",
    "🎤 如何和猫咪进行有效沟通？",
    "🕶️ 如何保护猫咪的眼睛？",
    "🌲 如何为猫咪选择合适的树木？",
    "🎥 如何记录猫咪的成长？",
    "📺 猫咪是否喜欢看电视？",
    "💻 如何利用科技帮助猫咪？",
    "🏖️ 如何带猫咪度假？",
    "💭 猫咪会有情感共鸣吗？",
    "🎨 如何激发猫咪的创造力？",
    "🏠 猫咪的家居环境如何影响健康？",
    "🚪 猫咪的家是否需要围栏？",
    "📊 如何跟踪猫咪的健康数据？",
    "🌻 如何为猫咪选择合适的花卉？",
    "🧪 如何判断猫咪的食物过敏？",
    "🌍 猫咪如何适应不同的气候？",
    "🎈 如何为猫咪策划派对？",
    "🌙 猫咪的夜间习性如何影响主人？",
    "🧭 如何引导猫咪找到自己的空间？",
    "🍵 猫咪是否能喝茶？",
    "🛡️ 如何保护猫咪免受伤害？",
    "💼 如何带猫咪参加活动？",
    "🐢 猫咪能和其他宠物和平相处吗？",
    "🏡 如何设计猫咪的安全避难所？",
    "🎇 如何处理猫咪的紧张情绪？"
];
