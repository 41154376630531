import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Parallax from './components/Parallax';
import Chatbot from './components/Chatbot';
import Loader from './components/Loader'; // 导入加载动画组件
import './index.css';
import Footer from './components/Footer';

const App = () => {
  const [loading, setLoading] = useState(true); // 初始化为 true

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // 停止加载
      setTimeout(() => {
        window.scrollTo(0, 0); // 加载结束后滚动到页面顶端
      }, 50); // 延迟执行以确保 DOM 更新
    }, 3000); // 3秒

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Parallax />
          <div className="content">
            <div className="chatbot-container" id="chatbot">
              <Chatbot />
            </div>
            <div className="map-container" id="map">
              <Footer /> {/* 添加 MapContainer 显示地图 */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default App;
