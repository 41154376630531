import React from 'react';
import '../Footer.css';  // 外部CSS文件，用于样式

const Footer = () => {
  // 返回顶部的函数
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动到顶部
  };

  return (
    <footer className="footer-container">
      <h4>Essence Lab</h4>
      <p>Room 106, Building 31, Communication University of China, Beijing, China</p>
      <p>Phone: +86 137 8727 0915</p>
      <div className="footer-bottom">
        <p>
          © 2024 Essence Lab. All Rights Reserved | 
          <a href="#" onClick={scrollToTop}> 返回顶部</a> | 
          <a href="#"> Privacy Policy</a> | 
          <a href="#"> Terms of Service</a> | 湘ICP备2024088908号
        </p>
      </div>
    </footer>
  );
};

export default Footer;
