import React, { useState, useEffect, useRef } from 'react';
import Message from './Message';
import useChatbot from '../hooks/useChatbot';
import { allQuestions } from '../config/questions'; // 更新导入路径
import '../Chatbot.css';

const Chatbot = () => {
    const { messages, userInput, setUserInput, handleSend, loading } = useChatbot();
    const chatEndRef = useRef(null);
    const [isThinking, setIsThinking] = useState(false);
    const [typedMessage, setTypedMessage] = useState('');
    const [dots, setDots] = useState('');
    const [questions, setQuestions] = useState([]);
    const [maxQuestions, setMaxQuestions] = useState(5); // 默认最大问题数

    useEffect(() => {
        getRandomQuestions();
        updateMaxQuestions(); // 初始化时更新最大问题数
        window.addEventListener('resize', updateMaxQuestions); // 监听窗口大小变化
        return () => window.removeEventListener('resize', updateMaxQuestions); // 清理事件监听
    }, []);

    const updateMaxQuestions = () => {
        const width = window.innerWidth;
        setMaxQuestions(width < 1000 ? 3 : 5); // 根据窗口宽度设置最大问题数
    };

    const getRandomQuestions = () => {
        const shuffled = [...allQuestions].sort(() => 0.5 - Math.random());
        setQuestions(shuffled.slice(0, maxQuestions)); // 根据最大问题数更新
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && userInput.trim() !== '') {
            handleSendMessage();
        }
    };

    const handleSuggestedQuestion = async (question) => {
        setUserInput(question);
        setIsThinking(true);
        try {
            const fullMessage = await handleSend();
            if (fullMessage && typeof fullMessage === 'string') {
                typeMessage(fullMessage);
            }
        } catch (error) {
            console.error('发送消息时出错:', error);
        } finally {
            setIsThinking(false);
            getRandomQuestions();
        }
    };

    const typeMessage = (message) => {
        if (!message || typeof message !== 'string') return;
        let index = 0;
        setTypedMessage('');
        const interval = setInterval(() => {
            setTypedMessage(prev => prev + message[index]);
            index++;
            if (index >= message.length) {
                clearInterval(interval);
            }
        }, 50);
    };

    const handleSendMessage = async () => {
        if (loading) return;
        setIsThinking(true);
        setTypedMessage('');
        try {
            const fullMessage = await handleSend();
            if (fullMessage && typeof fullMessage === 'string') {
                typeMessage(fullMessage);
            } else {
                console.error('Received invalid message:', fullMessage);
            }
        } catch (error) {
            console.error('发送消息时出错:', error);
        } finally {
            setIsThinking(false);
        }
    };

    useEffect(() => {
        if (isThinking) {
            const dotInterval = setInterval(() => {
                setDots(prevDots => (prevDots.length === 3 ? '' : prevDots + '.'));
            }, 500);
            return () => clearInterval(dotInterval);
        } else {
            setDots('');
        }
    }, [isThinking]);

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' }); // 滚动到底部
    }, [messages, typedMessage, isThinking]);

    return (
        <div className="chatbot-container">
            <h2 className="title">Chat with kAItty</h2>
            <div className="chatbox">
                {messages.map((msg, index) => (
                    <Message key={index} message={msg} className="message" />
                ))}
                {isThinking && (
                    <Message message={{ role: 'ai', content: '正在输入' + dots }} />
                )}
                {typedMessage && (
                    <Message message={{ role: 'ai', content: typedMessage }} />
                )}
                <div ref={chatEndRef} />
            </div>

            {/* Suggested questions */}
            <div className="question-suggestion">
                {questions.map((question, index) => (
                    <button
                        key={index}
                        onClick={() => handleSuggestedQuestion(question)}
                        className="suggested-button"
                    >
                        {question}
                    </button>
                ))}
            </div>

            <div className="input-container">
                <input
                    type="text"
                    value={userInput}
                    onChange={e => setUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="有什么想要向kAItty咨询的吗..."
                    className="input-field"
                />
                <button
                    onClick={handleSendMessage}
                    disabled={loading || userInput.trim() === ''}
                    className="send-button"
                >
                    {loading ? '发送中...' : '发送'}
                </button>
            </div>
        </div>
    );
};

export default Chatbot;
